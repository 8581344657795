import React from 'react';
import { Box, Button, CircularProgress, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useCurrentQuery } from '../../redux/services/auth';
import HeaderComponent from '../../screens/header-component';

export const AuthComponent = ({ children }: { children: JSX.Element }) => {
  const { isLoading, error } = useCurrentQuery({ token: localStorage.getItem('access') || '' });

  React.useEffect(() => {
    document.title = 'Загрузка | КлинОк';
  }, []);

  if (error) {
    if ('status' in error) {
      if (error.status === 'FETCH_ERROR') {
        return (
          <Box sx={{ display: 'flex', height: '100dvh', flexDirection: 'column' }}>
            <HeaderComponent type={'loading'} />
            <Container
              component="div"
              maxWidth="xs"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                flexDirection: 'column',
                mb: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexGrow: 1,
                }}
              >
                <Typography
                  sx={{
                    mt: 4,
                    textAlign: 'center',
                    color: '#000',
                    marginBottom: '16px',
                    fontSize: '28px',
                    fontWeight: 'normal',
                    paddingLeft: '10%',
                    paddingRight: '10%',
                  }}
                >
                  Технический перерыв
                </Typography>
                <Typography
                  sx={{
                    mb: 4,
                    textAlign: 'center',
                    color: '#000',
                    marginBottom: '16px',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    paddingLeft: '10%',
                    paddingRight: '10%',
                  }}
                >
                  Через несколько секунд мы снова заработаем.
                </Typography>
              </Box>
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Обновить
              </Button>
            </Container>
          </Box>
        );
      }
    }
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', height: '100dvh', flexDirection: 'column' }}>
        <HeaderComponent type={'loading'} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 4 }}>Идет загрузка данных</Typography>
          <Typography sx={{ mb: 4 }}>Пожалуйста, подождите</Typography>
        </Box>
      </Box>
    );
  }

  return children;
};
